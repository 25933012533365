import * as Sentry from "@sentry/react"
import initParams, {
  getSentryDialogOptions as getDialogOptions,
  setDefaultUser,
  shouldShowDialog,
  shouldSkipEvent,
  shouldUseSentry,
} from "./sentry_integration"
import { registerModalElement } from "ui/modal-manager"
import { showReportDialog } from "@sentry/browser"
import Alert from "ui/Alert"

export function getSentryDialogOptions() {
  const options = getDialogOptions()
  const { onLoad } = options
  options.onLoad = (...args) => {
    registerModalElement($(".sentry-error-embed-wrapper")[0])
    onLoad(...args)
  }
  return options
}

export { setDefaultUser }

export default function getSentryInitParams() {
  const params = initParams()
  params.beforeSend = (event, hint) => {
    if (shouldSkipEvent(event, hint)) return null
    if (shouldShowDialog(event, hint)) {
      const options = getSentryDialogOptions()
      options.eventId = event.event_id
      showReportDialog(options)
    }
    return event
  }
  return params
}

export function initSentry(options) {
  if (shouldUseSentry() && !isSentryInitialized()) {
    if (options == null) options = getSentryInitParams()
    Sentry.init(options)
    installReportShortcut()
  }
}

export function isSentryInitialized() {
  return Sentry.getCurrentHub().getClient() != null
}

export function isReport(evt) {
  return /^\[Report]/.test(evt.message)
}

export function installReportShortcut() {
  const sendReport = () => {
    Alert.fire({
      icon: "warning",
      title: "Czy wysłać raport?",
      html: "Wpisz krótki tytuł",
      input: "text",
    }).then((result) => {
      if (result.value) Sentry.captureMessage("[Report] " + result.value)
    })
  }

  $(document).on("keydown.sentry", (evt) => {
    if (evt.ctrlKey && evt.key === "F1") sendReport()
  })
  let fingers = 0
  let last = 0
  $(document).on("touchstart.sentry", () => {
    fingers += 1
    if (fingers === 3)
      if (Date.now() - last < 200) sendReport()
      else last = Date.now()
  })
  $(document).on("touchend.sentry", () => {
    fingers -= 1
  })
}
