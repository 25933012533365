import ModalManager from "react-overlays/ModalManager"

let manager = null

export function getModalManager() {
  if (!manager) manager = new ModalManager()
  return manager
}

export function registerModalElement(element) {
  getModalManager().add(element, document.body)
  var observer = new MutationObserver(function (mutations) {
    if (!document.body.contains(element)) {
      getModalManager().remove(element)
      observer.disconnect()
    }
  })
  observer.observe(document.body, { childList: true })
}
