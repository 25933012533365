const DEFAULT_API_URL = process.env.API_URL
const DEFAULT_DEVICE_SERVER_URL = process.env.DEVICE_URL

export function getUrls() {
  return {
    api: sessionStorage.api_url ?? localStorage.api_url ?? window.API_URL ?? DEFAULT_API_URL,
    device: sessionStorage.device_url ?? localStorage.device_url ?? window.DEVICE_URL ?? DEFAULT_DEVICE_SERVER_URL,
    device2: sessionStorage.device2_url ?? localStorage.device2_url ?? window.DEVICE2_URL ?? "",
  }
}
