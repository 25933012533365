import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import "./Alert.scss"
import { registerModalElement } from "ui/modal-manager"

const withFullscreenTarget = (Swal) => {
  return class extends Swal {
    static argsToParams(args) {
      if (args[0].target == null && document.fullscreenElement) {
        args[0].target = document.fullscreenElement
        args[0].aboveFullScreen = true
      }

      return Swal.argsToParams(args)
    }

    _main(params) {
      const promise = super._main(params)
      if (params.aboveFullScreen)
        return promise.then(
          (result) => new Promise((resolve) => setTimeout(() => resolve(result), 250))
        )
      return promise
    }
  }
}
const Alert = withFullscreenTarget(
  withReactContent(
    Swal.mixin({
      willOpen: function (el) {
        if (!this.toast) registerModalElement(el)
      },
      scrollbarPadding: false,
      customClass: {
        confirmButton: `btn btn-primary`,
        cancelButton: `btn btn-outline-danger`,
        denyButton: `btn btn-danger`,
      },
      buttonsStyling: false,
    })
  )
)
export const Toast = withFullscreenTarget(
  withReactContent(
    Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer)
        toast.addEventListener("mouseleave", Swal.resumeTimer)
      },
    })
  )
)
export default Alert
export const ALERT_TYPES = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning",
  INFO: "info",
  QUESTION: "question",
}

export function showAlert(type, title, message, other) {
  return Alert.fire({
    icon: type,
    title: title,
    text: message ? message : "",
    ...other,
  })
}

export function showResultAlert(isError, error_message, success_message, other) {
  return Alert.fire({
    title: isError
      ? error_message ?? window._t("alert.error")
      : success_message ?? window._t("alert.success"),
    icon: isError ? "error" : "success",
    text: isError ? window._t("alert.try_again") : undefined,
    ...other,
  })
}

export function askAlert(
  title,
  action,
  {
    text,
    html,
    confirm,
    cancel,
    cancelAction = () => {},
    confirm_variant,
    cancel_variant,
    deny_variant,
    ...other
  }
) {
  Alert.fire({
    icon: "warning",
    title: title,
    text: text,
    html: html,
    showCancelButton: true,
    cancelButtonText: cancel ?? window._t("button.no"),
    confirmButtonText: confirm ?? window._t("button.yes"),
    customClass: {
      confirmButton: `btn ${confirm_variant ? `btn-${confirm_variant}` : "btn-primary"}`,
      cancelButton: `btn ${cancel_variant ? `btn-${cancel_variant}` : "btn-outline-danger"}`,
      denyButton: `btn ${deny_variant ? `btn-${deny_variant}` : "btn-danger"}`,
    },
    buttonsStyling: false,
    ...other,
  }).then((result) => {
    if (result.value) {
      action()
    } else {
      cancelAction(result)
    }
  })
}

setInterval(() => {
  const swalWasDestroyedWithFullscreenElement = () =>
    document.body.classList.contains("swal2-shown") && !document.querySelector(".swal2-container")
  if (swalWasDestroyedWithFullscreenElement()) {
    setTimeout(() => {
      if (swalWasDestroyedWithFullscreenElement()) {
        document.body.classList.remove("swal2-shown")
        document.body.classList.remove("swal2-height-auto")
      }
    }, 1000)
  }
}, 2000)
