import React from "react"
import ReactDOM from "react-dom"
import { showAlert } from "neuroforma-panel/src/ui/Alert"
import "neuroforma-panel/src/common.scss"
import { getUrls } from "server_urls/get-urls"
import getSentryInitParams, { initSentry, isReport } from "exercise-runner/react_sentry_integration"
import {pick} from "lodash";
const args = Object.fromEntries(new URLSearchParams(window.location.search))

const SESSION_PARAMS = ["api_url", "device_url", "device2_url", "hasTreadmill"]
const params = pick(args, SESSION_PARAMS)
if (Object.values(params).length) Object.assign(sessionStorage, params)

let sentryParams = getSentryInitParams()
if (/electron/i.test(navigator.userAgent)) {
  const { ipcRenderer } = window.require("electron")
  const { beforeSend } = sentryParams
  sentryParams.beforeSend = (event, hint) => {
    const newEvt = beforeSend(event, hint)
    if (isReport(newEvt)) ipcRenderer.send("send-report", newEvt.message)
    return newEvt
  }
}

initSentry(sentryParams)
let root = document.getElementById("root")
const urls = getUrls()
const location = window.location
if (location.protocol !== "https:" && urls.api) {
  const http = urls.api.replace("https:", "http:").replace("11112", "11111")
  const ping_url = urls.api + "/ping"
  const checking = new Promise((resolve, reject) => {
    setTimeout(reject, 4000)
    fetch(ping_url)
      .then(async (response) => {
        const urls = await response.json()
        location.replace(`${urls.HTTPS_URL}${location.pathname}${location.search}${location.hash}`)
      })
      .catch(reject)
  })
  checking.catch((e) => {
    showAlert("error", "Brak zainstalowanego certyfikatu", null, {
      html: `Aby kontynuować:<ol><li>Ściągnij <a href='${http}/root_ca/'>certyfikat</a></li>
        <li>'Uruchom' certyfikat aby go zainstalować</li>
        <li>[Windows] Podczas instalacji umieść certyfikat w magazynie: <strong>Zaufane główne urzędy certyfikacji</strong></li>
        <li>Zrestartuj przeglądarkę lub użyj trybu incognito1</li>`,
      showDenyButton: true,
      denyButtonText: "Ręcznie zaakceptuj certyfikat",
    }).then((result) => {
      if (result.isConfirmed) window.location.href = http + "/root_ca/"
      else window.location.href = urls.api
    })
  })
} else {
  const SetupPro = React.lazy(() => import("./setup"))
  ReactDOM.render(
    <React.Suspense fallback={""}>
      <SetupPro backendUrl={(urls.api ?? "https://localhost:8001") + "/api"} />
    </React.Suspense>,
    root
  )
}
